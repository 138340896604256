
export function loader(data) {
    return 0
}
export default function Guide() {

    return (

        <>

<div id="questions">
      <div className="r-menu">
        <div className="r-menu-tablet">
          <div className="r-menu-tablet-r">
            <a href="#">
              <i className="fa-solid fa-fingerprint fa-xl"></i>
              <p>ثبت نام و احراز هویت</p>
            </a>
            <a href="#">
              <i className="fa-solid fa-arrow-right-arrow-left fa-xl"></i>
              <p>خرید و فروش</p>
            </a>
            <a href="#">
              <i className="fa-light fa-user fa-xl"></i>
              <p>حساب کاربری</p>
            </a>
            <a href="#">
              <i className="fa-light fa-arrows-rotate fa-xl"></i>
              <p>واریز و برداشت ها</p>
            </a>
            <a href="#">
              <i className="fa-light fa-user-plus fa-xl"></i>
              <p>دعوت دوستان</p>
            </a>
          </div>
          <div className="r-menu-tablet-l">
            <a href="#">
              <i className="fa-light fa-lock-keyhole fa-xl"></i>
              <p>امنیت حساب کاربری</p>
            </a>
            <a href="#">
              <i className="fa-light fa-coin fa-xl"></i>
              <p>بیگیری سفارشات</p>
            </a>
            <a href="#">
              <i className="fa-light fa-memo-pad fa-xl"></i>
              <p>سوالات متفرقه</p>
            </a>
          </div>
        </div>
        <a href="#">
          <i className="fa-solid fa-fingerprint fa-xl"></i>
          <p>ثبت نام و احراز هویت</p>
        </a>
        <a href="#">
          <i className="fa-solid fa-arrow-right-arrow-left fa-xl"></i>
          <p>خرید و فروش</p>
        </a>
        <a href="#">
          <i className="fa-light fa-user fa-xl"></i>
          <p>حساب کاربری</p>
        </a>
        <a href="#">
          <i className="fa-light fa-arrows-rotate fa-xl"></i>
          <p>واریز و برداشت ها</p>
        </a>
        <a href="#">
          <i className="fa-light fa-user-plus fa-xl"></i>
          <p>دعوت دوستان</p>
        </a>
        <a href="#">
          <i className="fa-light fa-lock-keyhole fa-xl"></i>
          <p>امنیت حساب کاربری</p>
        </a>
        <a href="#">
          <i className="fa-light fa-coin fa-xl"></i>
          <p>بیگیری سفارشات</p>
        </a>
        <a href="#">
          <i className="fa-light fa-memo-pad fa-xl"></i>
          <p>سوالات متفرقه</p>
        </a>
      </div>
      <div className="que-awns">
        <div className="awns">
          <h3>چگونه ویدیوی احراز هویت ارسال کنیم؟</h3>
          <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و
            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
            متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
            جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی،
            و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در اار گیرد.</p>
        </div>
        <div className="awns">
          <h3>چگونه ویدیوی احراز هویت ارسال کنیم؟</h3>
          <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و
            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
            متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
            جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی،
            و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در اار گیرد.</p>
        </div>
        <div className="awns">
          <h3>چگونه ویدیوی احراز هویت ارسال کنیم؟</h3>
          <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و
            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
            متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
            جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی،
            و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در اار گیرد.</p>
        </div>
        <div className="awns">
          <h3>چگونه ویدیوی احراز هویت ارسال کنیم؟</h3>
          <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و
            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
            متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
            جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی،
            و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در اار گیرد.</p>
        </div>
        <div className="awns">
          <h3>چگونه ویدیوی احراز هویت ارسال کنیم؟</h3>
          <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و
            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
            متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
            جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی،
            و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در اار گیرد.</p>
        </div>
        <div className="awns">
          <h3>چگونه ویدیوی احراز هویت ارسال کنیم؟</h3>
          <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و
            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
            متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
            جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی،
            و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در اار گیرد.</p>
        </div>
        <div className="awns">
          <h3>چگونه ویدیوی احراز هویت ارسال کنیم؟</h3>
          <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و
            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
            متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
            جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی،
            و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در اار گیرد.</p>
        </div>
        <div className="awns">
          <h3>چگونه ویدیوی احراز هویت ارسال کنیم؟</h3>
          <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و
            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
            متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
            جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی،
            و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در اار گیرد.</p>
        </div>
      </div>
      
    </div>

    <div id="guide-module">
      <div className="que-awns">
        <div className="awns">
          <h3>چگونه ویدیوی احراز هویت ارسال کنیم؟</h3>
          <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و
            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
            متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
            جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی،
            و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در اار گیرد.</p>
        </div>
        <div className="awns">
          <h3>چگونه ویدیوی احراز هویت ارسال کنیم؟</h3>
          <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و
            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
            متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
            جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی،
            و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در اار گیرد.</p>
        </div>
        <div className="awns">
          <h3>چگونه ویدیوی احراز هویت ارسال کنیم؟</h3>
          <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و
            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
            متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
            جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی،
            و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در اار گیرد.</p>
        </div>
        <div className="awns">
          <h3>چگونه ویدیوی احراز هویت ارسال کنیم؟</h3>
          <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و
            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
            متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
            جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی،
            و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در اار گیرد.</p>
        </div>
        <div className="awns">
          <h3>چگونه ویدیوی احراز هویت ارسال کنیم؟</h3>
          <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و
            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
            متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
            جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی،
            و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در اار گیرد.</p>
        </div>
        <div className="awns">
          <h3>چگونه ویدیوی احراز هویت ارسال کنیم؟</h3>
          <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و
            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
            متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
            جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی،
            و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در اار گیرد.</p>
        </div>
        <div className="awns">
          <h3>چگونه ویدیوی احراز هویت ارسال کنیم؟</h3>
          <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و
            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
            متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
            جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی،
            و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در اار گیرد.</p>
        </div>
        <div className="awns">
          <h3>چگونه ویدیوی احراز هویت ارسال کنیم؟</h3>
          <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و
            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
            متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
            جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی،
            و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در اار گیرد.</p>
        </div>
      </div>
    </div>



        </>

    )

}