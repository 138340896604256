import { Link, Outlet } from "react-router-dom"

export function loader(data) {
    return 0
}
export default function RecentVisit() {

    return (

        <>
          
          <div class="history">
        <div class="title">
          <h4>بازدیدهای اخیر</h4>
          <div class="container">
            <div class="popup">
              <i class="fa-solid fa-ellipsis-vertical"></i>
            </div>
          </div>

        </div>
        <div class="details">

          <div class="items">
            <section class="item">
              <Link to={"/single-product"} class="product">
              <img
                  src={require("../../../assets/img/blus-boy.png")}
                  alt=""
                />
                <h5 class="name">دورس مردانه</h5>
                <div class="rate">
                  <p class="r1">اسپرت مردانه</p>
                  <div class="star">
                    <span>4.1</span>
                    <i class="fa-solid fa-star"></i>
                  </div>
                </div>
                <div class="cost">
                  <p class="amount">۱,۲۵۰,۰۰۰</p>
                  <p>تومان</p>
                </div>
                <a href="#">افزودن به سبد خرید</a>
              </Link>
              <Link to={"/single-product"} class="product">
              <img
                  src={require("../../../assets/img/blus-girl.png")}
                  alt=""
                />
                <h5 class="name">دورس مردانه</h5>
                <div class="rate">
                  <p class="r1">اسپرت مردانه</p>
                  <div class="star">
                    <span>4.1</span>
                    <i class="fa-solid fa-star"></i>
                  </div>
                </div>
                <div class="cost">
                  <p class="amount">۱,۲۵۰,۰۰۰</p>
                  <p>تومان</p>
                </div>
                <a href="#">افزودن به سبد خرید</a>
              </Link>
              <Link to={"/single-product"} class="product">
              <img
                  src={require("../../../assets/img/blus-boy.png")}
                  alt=""
                />
                <h5 class="name">دورس مردانه</h5>
                <div class="rate">
                  <p class="r1">اسپرت مردانه</p>
                  <div class="star">
                    <span>4.1</span>
                    <i class="fa-solid fa-star"></i>
                  </div>
                </div>
                <div class="cost">
                  <p class="amount">۱,۲۵۰,۰۰۰</p>
                  <p>تومان</p>
                </div>
                <a href="#">افزودن به سبد خرید</a>
              </Link>
              <Link to={"/single-product"} class="product">
              <img
                  src={require("../../../assets/img/blus-girl.png")}
                  alt=""
                />
                <h5 class="name">دورس مردانه</h5>
                <div class="rate">
                  <p class="r1">اسپرت مردانه</p>
                  <div class="star">
                    <span>4.1</span>
                    <i class="fa-solid fa-star"></i>
                  </div>
                </div>
                <div class="cost">
                  <p class="amount">۱,۲۵۰,۰۰۰</p>
                  <p>تومان</p>
                </div>
                <a href="#">افزودن به سبد خرید</a>
              </Link>




            </section>
          </div>


        </div>

      </div>

        </>

    )

}