import { Link } from "react-router-dom"

export function loader(data) {
    return 0
}
export default function CompareProducts() {

    return (

        <>
            <div id="compare-product">
                <div class="pro-row">
                    <Link to={"/single-product"}  href="#">
                    <img src={require("../assets/img/imggg11.png")} />
                        <p>لباس دخترانه</p>
                        <div class="pro-rate">
                            <h6>اسپرت بچگانه</h6>
                            <span>4.1</span>
                            <i class="fa-solid fa-star"></i>
                        </div>
                        <div class="pro-price">
                            <p>۱,۲۵۰,۰۰۰</p>
                            <span>تومان</span>
                        </div>
                        <button>افزودن به سبد خرید</button>
                    </Link>
                    <Link to={"/single-product"} href="#">
                    <img src={require("../assets/img/imggg33.png")} />
                        <p>لباس دخترانه</p>
                        <div class="pro-rate">
                            <h6>اسپرت بچگانه</h6>
                            <span>4.1</span>
                            <i class="fa-solid fa-star"></i>
                        </div>
                        <div class="pro-price">
                            <p>۱,۲۵۰,۰۰۰</p>
                            <span>تومان</span>
                        </div>
                        <button>افزودن به سبد خرید</button>
                    </Link>
                    <Link to={"/single-product"} href="#">
                    <img src={require("../assets/img/imggg44.png")} />
                        <p>لباس دخترانه</p>
                        <div class="pro-rate">
                            <h6>اسپرت بچگانه</h6>
                            <span>4.1</span>
                            <i class="fa-solid fa-star"></i>
                        </div>
                        <div class="pro-price">
                            <p>۱,۲۵۰,۰۰۰</p>
                            <span>تومان</span>
                        </div>
                        <button>افزودن به سبد خرید</button>
                    </Link>
                    <Link to={"/single-product"} href="#">
                    <img src={require("../assets/img/imggg55.png")} />
                        <p>لباس دخترانه</p>
                        <div class="pro-rate">
                            <h6>اسپرت بچگانه</h6>
                            <span>4.1</span>
                            <i class="fa-solid fa-star"></i>
                        </div>
                        <div class="pro-price">
                            <p>۱,۲۵۰,۰۰۰</p>
                            <span>تومان</span>
                        </div>
                        <button>افزودن به سبد خرید</button>
                    </Link>
                </div>
            </div>
            <div id="compare-org">
                <div class="org-row">
                    <h3>جنس پارچه</h3>
                    <span></span>
                    <div class="com-btns">
                        <button>نخ پنبه</button>
                        <button>نخ پنبه</button>
                        <button>نخ پنبه</button>
                        <button>نخ پنبه</button>
                    </div>
                </div>
                <div class="org-row">
                    <h3>فاق</h3>
                    <span></span>
                    <div class="com-btns">
                        <button>بلند</button>
                        <button>بلند</button>
                        <button>بلند</button>
                        <button>بلند</button>
                    </div>
                </div>
                <div class="org-row">
                    <h3>موارد استفاده</h3>
                    <span></span>
                    <div class="com-btns">
                        <button>روزمره</button>
                        <button>روزمره</button>
                        <button>روزمره</button>
                        <button>روزمره</button>
                    </div>
                </div>
                <div class="org-row">
                    <h3>طرح</h3>
                    <span></span>
                    <div class="com-btns">
                        <button>مام فیت</button>
                        <button>مام فیت</button>
                        <button>مام فیت</button>
                        <button>مام فیت</button>
                    </div>
                </div>
                <div class="org-row">
                    <h3>زاپ</h3>
                    <span></span>
                    <div class="com-btns">
                        <button><i class="fa-solid fa-check"></i></button>
                        <button><i class="fa-solid fa-check"></i></button>
                        <button><i class="fa-solid fa-xmark"></i></button>
                        <button><i class="fa-solid fa-check"></i></button>
                    </div>
                </div>
                <div class="org-row">
                    <h3>جیب</h3>
                    <span></span>
                    <div class="com-btns">
                        <button>دارد</button>
                        <button>دارد</button>
                        <button>دارد</button>
                        <button>دارد</button>
                    </div>
                </div>
                <div class="org-row">
                    <h3>قد لباس</h3>
                    <span></span>
                    <div class="com-btns">
                        <button>95 سانتی متر</button>
                        <button>95 سانتی متر</button>
                        <button>95 سانتی متر</button>
                        <button>95 سانتی متر</button>
                    </div>
                </div>
                <div class="org-row">
                    <h3>نوع شستشو</h3>
                    <span></span>
                    <div class="com-btns">
                        <button>ماشین/دستی</button>
                        <button>ماشین/دستی</button>
                        <button>ماشین/دستی</button>
                        <button>ماشین/دستی</button>
                    </div>
                </div>
            </div>

        </>

    )

}