import { Link } from "react-router-dom"

export function loader(data) {
    return 0
}
export default function ChooseCompare() {

    return (

        <>
            <div id="choose-product">
                <div className="function">
                   
                    <img src={require("../assets/img/Group.png")} alt="#" />
                    <p>برای مقایسه کالای مورد نظر را انتخاب کنید</p>
                    <Link to={"/all-product"}>انتخاب کالا</Link>
                </div>
                <div className="func-text">
                    <p>
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه  فرهنگ پیشرو در زبان فارسی ایجاد کرد.



                    </p>
                </div>
            </div>

        </>

    )

}