import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/css/style.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Router, RouterProvider, createBrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Store from "./Store";
import WomenSet, { loader as womensetLoader } from "./component/WomenSet";
import SingleProduct, {
  loader as singleproductLoader,
} from "./component/SingleProduct";
import "../src/assets/fontawsome/css/all.css";
import SellSet, { loader as sellsetLoader } from "./component/SellSet";
import HomePage, { loader as homepageLoader } from "./component/HomePage";
import KidBoy, { loader as kidboyLoader } from "./component/KidBoy";
import KidGirl, { loader as kidgirlLoader } from "./component/KidGirl";
import KidBaby, { loader as kidbabyLoader } from "./component/KidBaby";
import SignVerify, { loader as signverifyLoader } from "./component/SignVerify";
import SetPassword, {
  loader as setpasswordLoader,
} from "./component/SetPassword";
import NumberExist, {
  loader as numberexistLoader,
} from "./component/NumberExist";
import SearchResault, {
  loader as searchresaultLoader,
} from "./component/SearchResault";
import Cathegory, { loader as cathegoryLoader } from "./component/Cathegory";
import CompletePurchase, {
  loader as completepurchaseLoader,
} from "./component/CompletePurchase";
import EmailExist, { loader as emailexistLoader } from "./component/EmailExist";
import SetInformation, {
  loader as setinformationLoader,
} from "./component/SetInformation";
import ForgetVerify, {
  loader as forgetverifyLoader,
} from "./component/ForgetVerify";
import EmailNumber, {
  loader as emailnumberLoader,
} from "./component/EmailNumber";
import ForgetPassword, {
  loader as forgetpasswordLoader,
} from "./component/ForgetPassword";
import ChangePassword, {
  loader as changepasswordLoader,
} from "./component/ChangePassword";
import Blog, { loader as blogLoader } from "./component/Blog";
import AboutUs, { loader as aboutusLoader } from "./component/AboutUs";
import CallUs, { loader as callusLoader } from "./component/CallUs";
import ChooseCompare, {
  loader as choosecompareLoader,
} from "./component/ChooseCompare";
import Guide, { loader as guideLoader } from "./component/Guide";
import AllProduct, { loader as AllproductLoader } from "./component/AllProduct";
import CartPage, { loader as cartpageLoader } from "./component/CartPage";
import FrequentlyQuestion, {
  loader as frequentlyquestionLoader,
} from "./component/FrequentlyQuestion";
import FrequentlyAnswer, {
  loader as frequentlyanswerLoader,
} from "./component/FrequentlyAnswer";
import CompareProducts, {
  loader as compareproductsLoader,
} from "./component/CompareProducts";
import Profile, { loader as profileLoader } from "./component/profile/Profile";
import ProfileInformation, {
  loader as profileinformationLoader
} from "./component/profile/content/ProfileInformation";
import RecentVisit, {
  loader as recentvisitLoader
} from "./component/profile/content/RecentVisit";
import OrderHistory, { loader as orderhistoryLoader } from "./component/profile/content/OrderHistory";
import Opinions, { loader as opnionsLoader } from "./component/profile/content/Opnions";
import Addresses, { loader as addressesLoader } from "./component/profile/content/Addresses";
import CodeOff, { loader as codeoffLoader } from "./component/profile/content/CodeOff";
import Favourite,{loader as favouriteLoader} from "./component/profile/content/Favourite";
import Massages,{loader as massagesLoader} from "./component/profile/content/Massages";
import OrderHistoryClick,{loader as orderhistoryclickLoader} from "./component/profile/content/OrderHistoryClick";
import OrderHistoryCancell,{loader as orderhistorycancellLoader} from "./component/profile/content/OrderHistoryCancell";


const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "women-set",
        element: <WomenSet />,
        loader: womensetLoader,
      },
      {
        path: "single-product",
        element: <SingleProduct />,
        loader: singleproductLoader,
      },
      {
        path: "sell-Set",
        element: <SellSet />,
        loader: sellsetLoader,
      },
      {
        path: "home-page",
        element: <HomePage />,
        loader: homepageLoader,
      },
      {
        path: "kid-boy",
        element: <KidBoy />,
        loader: kidboyLoader,
      },
      {
        path: "kid-girl",
        element: <KidGirl />,
        loader: kidgirlLoader,
      },
      {
        path: "kid-baby",
        element: <KidBaby />,
        loader: kidbabyLoader,
      },
      {
        path: "sign-verify",
        element: <SignVerify />,
        loader: signverifyLoader,
      },
      {
        path: "set-password",
        element: <SetPassword />,
        loader: setpasswordLoader,
      },
      {
        path: "number-exist",
        element: <NumberExist />,
        loader: numberexistLoader,
      },
      {
        path: "search-resault",
        element: <SearchResault />,
        loader: searchresaultLoader,
      },
      {
        path: "cathegory",
        element: <Cathegory />,
        loader: cathegoryLoader,
      },
      {
        path: "complete-purchase",
        element: <CompletePurchase />,
        loader: completepurchaseLoader,
      },
      {
        path: "email-exist",
        element: <EmailExist />,
        loader: emailexistLoader,
      },
      {
        path: "set-information",
        element: <SetInformation />,
        loader: setinformationLoader,
      },
      {
        path: "forget-verify",
        element: <ForgetVerify />,
        loader: forgetverifyLoader,
      },
      {
        path: "email-number",
        element: <EmailNumber />,
        loader: emailnumberLoader,
      },
      {
        path: "forget-password",
        element: <ForgetPassword />,
        loader: forgetpasswordLoader,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
        loader: changepasswordLoader,
      },
      {
        path: "blog",
        element: <Blog />,
        loader: blogLoader,
      },
      {
        path: "about-us",
        element: <AboutUs />,
        loader: aboutusLoader,
      },
      {
        path: "call-us",
        element: <CallUs />,
        loader: callusLoader,
      },
      {
        path: "choose-compare",
        element: <ChooseCompare />,
        loader: choosecompareLoader,
      },
      {
        path: "guide",
        element: <Guide />,
        loader: guideLoader,
      },
      {
        path: "all-product",
        element: <AllProduct />,
        loader: AllproductLoader,
      },
      {
        path: "cart-page",
        element: <CartPage />,
        loader: cartpageLoader,
      },
      {
        path: "frequently-question",
        element: <FrequentlyQuestion />,
        loader: frequentlyquestionLoader,
      },
      {
        path: "frequently-answer",
        element: <FrequentlyAnswer />,
        loader: frequentlyanswerLoader,
      },
      {
        path: "compare-product",
        element: <CompareProducts />,
        loader: compareproductsLoader,
      },
      {
        path: "profile",
        element: <Profile />,
        children: [
          {
            path: "profile-information",
            element: <ProfileInformation />,
            loader: profileinformationLoader,
          },
        
          {
            path: "recent-visit",
            element: <RecentVisit />,
            loader: recentvisitLoader,
          },
          {
            path: "order-history",
            element: <OrderHistory />,
            loader: orderhistoryLoader,
          },
          {
            path: "opnions",
            element: <Opinions />,
            loader: opnionsLoader,
          },
          {
            path: "addresses",
            element: <Addresses />,
            loader: addressesLoader,
          },
          {
            path: "code-off",
            element: <CodeOff />,
            loader: codeoffLoader,
          },
          {
            path: "favourite",
            element: <Favourite />,
            loader: favouriteLoader,
          },
          {
            path: "massages",
            element: <Massages />,
            loader: massagesLoader,
          },
          {
            path: "order-history-click",
            element: <OrderHistoryClick />,
            loader: orderhistoryclickLoader,
          },
          {
            path: "order-history-cancell",
            element: <OrderHistoryCancell />,
            loader: orderhistorycancellLoader,
          },
        ]
      },

    ],
  },
]);
root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();