
export function loader(data) {
    return 0
}
export default function Blog() {

    return (

        <>


            <div className="blog">
                <div id="hashtag">
                    <div className="hashtags-section">
                        <div className="hot-hashtags">
                            <p>هشتگ های داغ:</p>
                        </div>
                        <div className="box-hashtags">
                            <a href="#">#لباس_کودک</a>
                            <a href="#">#لباس_کودک</a>
                            <a href="#">#لباس_کودک</a>
                            <a href="#">#لباس_کودک</a>
                            <a href="#">#لباس_کودک</a>
                        </div>
                    </div>

                    <div className="image-section">

                        <div className="part1">
                            <div className="post1">
                            <img src={require("../assets/img/Frame1611.png")} alt="" />
                            
                            </div>
                            <div className="post2">
                            <img src={require("../assets/img/Frame6122.png")} alt="" />
                            </div>
                        </div>

                        <div className="part2">
                            <div className="post1">
                            <img src={require("../assets/img/Frame613.png")} alt="" />
                            </div>
                        </div>
                        <div className="part3">
                            <div className="post1">
                            <img src={require("../assets/img/Frame16611.png")} alt="" />
                            </div>
                            <div className="post2">
                            <img src={require("../assets/img/Frame6612.png")} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="fav-posts">
                    <div className="title">
                        <div className="select">
                            <h3>منتخب ها</h3>
                        </div>
                        <div className="more">
                            <a href="#">مشاهده عناوین بیشتر</a>
                        </div>
                    </div>

                    <div className="posts-section">

                        <div className="post">
                            <div className="image">
                            <img src={require("../assets/img/Frame58112.png")} alt="" />
                            </div>
                            <div className="text">
                                <h6>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک
                                    است.

                                </h6>
                            </div>
                            <div className="information">
                                <div className="logo-name">
                                    <div className="logo"><img src={require("../assets/img/Ellipse13.png")} alt="" /></div>
                                    <h6>لورم </h6>
                                </div>
                                <div className="date">

                                    <h6>لورم ایپسوم</h6>
                                </div>
                            </div>
                        </div>
                        <div className="post">
                            <div className="image">
                            <img src={require("../assets/img/Frame7581.png")} alt="" />
                            </div>
                            <div className="text">
                                <h6>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک
                                    است.

                                </h6>
                            </div>
                            <div className="information">
                                <div className="logo-name">
                                    <div className="logo"><img src={require("../assets/img/Ellipse13.png")} alt="" /></div>
                                    <h6>لورم </h6>
                                </div>
                                <div className="date">

                                    <h6>لورم ایپسوم</h6>
                                </div>
                            </div>
                        </div>
                        <div className="post">
                            <div className="image">
                            <img src={require("../assets/img/Frame581239.png")} alt="" />
                            </div>
                            <div className="text">
                                <h6>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک
                                    است.

                                </h6>
                            </div>
                            <div className="information">
                                <div className="logo-name">
                                    <div className="logo"><img src={require("../assets/img/Ellipse13.png")} alt="" /></div>
                                    <h6>لورم </h6>
                                </div>
                                <div className="date">

                                    <h6>لورم ایپسوم</h6>
                                </div>
                            </div>
                        </div>
                        <div className="post">
                            <div className="image">
                            <img src={require("../assets/img/Frame58197.png")} alt="" />
                            </div>
                            <div className="text">
                                <h6>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک
                                    است.

                                </h6>
                            </div>
                            <div className="information">
                                <div className="logo-name">
                                    <div className="logo"><img src={require("../assets/img/Ellipse13.png")} alt="" /></div>
                                    <h6>لورم </h6>
                                </div>
                                <div className="date">

                                    <h6>لورم ایپسوم</h6>
                                </div>
                            </div>
                        </div>
                        <div className="post">
                            <div className="image">
                            <img src={require("../assets/img/Frame97581.png")} alt="" />
                            </div>
                            <div className="text">
                                <h6>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک
                                    است.

                                </h6>
                            </div>
                            <div className="information">
                                <div className="logo-name">
                                    <div className="logo"><img src={require("../assets/img/Ellipse13.png")} alt="" /></div>
                                    <h6>لورم </h6>
                                </div>
                                <div className="date">

                                    <h6>لورم ایپسوم</h6>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>



                <div id="videos">

                    <div className="title">
                        <a href="#">جدیدترین ویدیوها</a>
                        <div className="more">
                            <a href="#">مشاهده عناوین بیشتر</a>
                        </div>
                    </div>

                    <div className="videos-section">

                        <div className="section1">
                            <div className="post">
                                <div className="image">
                                <img src={require("../assets/img/Frameee5985.png")} alt="" />
                                </div>
                                <div className="text">
                                    <a href="#">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از
                                        طراحان گرافیک است.

                                    </a>
                                </div>
                            </div>
                            <div className="post">
                                <div className="image">
                                <img src={require("../assets/img/Frameee597.png")} alt="" />
                                </div>
                                <div className="text">
                                    <a href="#">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از
                                        طراحان گرافیک است.

                                    </a>
                                </div>
                            </div>
                            <div className="post">
                                <div className="image">
                                <img src={require("../assets/img/Frameee598.png")} alt="" />
                                </div>
                                <div className="text">
                                    <a href="#">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از
                                        طراحان گرافیک است.

                                    </a>
                                </div>
                            </div>
                            <div className="post">
                                <div className="image">
                                <img src={require("../assets/img/Frameee5999.png")} alt="" />
                                </div>
                                <div className="text">
                                    <a href="#">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از
                                        طراحان گرافیک است.

                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="section2">

                            <div className="post">
                                <div className="clip">
                                <img src={require("../assets/img/video.png")} alt="" />
                                </div>
                                <div className="text">
                                    <a href="#">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از
                                        طراحان گرافیک است.

                                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان
                                        گرافیک است.
                                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان
                                        گرافیک است.</a>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>



















            </div>

















        </>

    )

}