import { Outlet } from "react-router-dom"

export function loader(data) {
    return 0
}
export default function Addresses() {

    return (

        <>
 <div id="address">
        <div class="title">
          <i class="icon" class="fa-light fa-arrow-right"></i>
          <h4>آدرس ها</h4>
          <div class="container">
            <div class="popup">
              <i class="fa-light fa-location-dot"></i>
              <a href="#">ثبت آدرس جدید</a>
            </div>
          </div>
        </div>

        <div class="details">
          <div class="items">
            <div class="add-home">
              <h5>بلوار امام رضا، بن. گلستان ۴۳/۱</h5>

              <div class="popup">
                <i class="fa-solid fa-ellipsis-vertical"></i>
              </div>
            </div>
            <div class="add-map">
              <div class="add-details">
                <div class="city">
                  <i class="fa-sharp fa-solid fa-location-dot"></i>
                  <p>لاهیجان</p>
                </div>
                <div class="post">
                  <i class="fa-sharp fa-solid fa-envelope"></i>
                  <p>۴۴۱۴۹۹۸۳۶۹</p>
                </div>
                <div class="phone">
                  <i class="fa-solid fa-phone"></i>
                  <p>09394922131</p>
                </div>
                <div class="name">
                  <i class="fa-solid fa-user"></i>
                  <p>امیرحسین جعفری</p>
                </div>
              </div>
              <img     src={require("../../../assets/img/map.jpg")} alt="#"/>
            </div>
          </div>
          <div class="items">
            <div class="add-home">
              <h5>بلوار امام رضا، بن. گلستان ۴۳/۱</h5>

              <div class="popup">
                <i class="fa-solid fa-ellipsis-vertical"></i>
              </div>
            </div>
            <div class="add-map">
              <div class="add-details">
                <div class="city">
                  <i class="fa-sharp fa-solid fa-location-dot"></i>
                  <p>لاهیجان</p>
                </div>
                <div class="post">
                  <i class="fa-sharp fa-solid fa-envelope"></i>
                  <p>۴۴۱۴۹۹۸۳۶۹</p>
                </div>
                <div class="phone">
                  <i class="fa-solid fa-phone"></i>
                  <p>09394922131</p>
                </div>
                <div class="name">
                  <i class="fa-solid fa-user"></i>
                  <p>امیرحسین جعفری</p>
                </div>
              </div>
              <img     src={require("../../../assets/img/map.jpg")} alt="#"/>
            </div>
          </div>


        </div>





      </div>

        </>

    )

}