import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

export function loader(data) {
  return 0;
}
export default function CartPage() {
  return (
    <>
      <div id="cart-main">
        <div id="cart-products">
          <Link to={"/single-product"} className="cart-pro">
            <div className="right-sec">
              <img src={require("../assets/img/Rectangle84.jpg")} />
              <div className="count">
                <i className="fa-solid fa-plus fa-xl"></i>
                <span>2</span>
                <i className="fa-solid fa-trash-can fa-xl"></i>
              </div>
            </div>
            <div className="left-sec">
              <h2>شلوار جین زنانه مدل مام فیت</h2>
              <div className="color-pro">
                <div className="col-itself"></div>
                <p>رنگ: آبی</p>
              </div>
              <div className="size-pro">
                <i className="fa-solid fa-arrows-up-down fa-xl "></i>
                <p>سایز: 2XL</p>
              </div>
              <div className="price-pro">
                <h5>450,000 تومان</h5>
                <h4>416,000 تومان</h4>
              </div>
            </div>
          </Link>
          <Link to={"/single-product"} className="cart-pro">
            <div className="right-sec">
              <img
                src={require("../assets/img/55a6c2cc48275acdbcf86a9ad2a9be2f.jpg")}
              />
              <div className="count">
                <i className="fa-solid fa-plus fa-xl"></i>
                <span>2</span>
                <i className="fa-solid fa-trash-can fa-xl"></i>
              </div>
            </div>
            <div className="left-sec">
              <h2>پیراهن آبی مردانه مدل بلواستایل</h2>
              <div className="color-pro">
                <div className="col-itself"></div>
                <p>رنگ: آبی</p>
              </div>
              <div className="size-pro">
                <i className="fa-solid fa-arrows-up-down fa-xl "></i>
                <p>سایز: 2XL</p>
              </div>
              <div className="price-pro">
                <h5>450,000 تومان</h5>
                <h4>416,000 تومان</h4>
              </div>
            </div>
          </Link>
          <Link to={"/single-product"} className="cart-pro">
            <div className="right-sec">
              <img
                src={require("../assets/img/d1725dcdc1938cf0af596beaf20ad453.jpg")}
              />
              <div className="count">
                <i className="fa-solid fa-plus fa-xl"></i>
                <span>2</span>
                <i className="fa-solid fa-trash-can fa-xl"></i>
              </div>
            </div>
            <div className="left-sec">
              <h2>شلوار کتان زنانه مدل راسته</h2>
              <div className="color-pro">
                <div className="col-itself"></div>
                <p>رنگ: آبی</p>
              </div>
              <div className="size-pro">
                <i className="fa-solid fa-arrows-up-down fa-xl "></i>
                <p>سایز: 2XL</p>
              </div>
              <div className="price-pro">
                <h5>450,000 تومان</h5>
                <h4>416,000 تومان</h4>
              </div>
            </div>
          </Link>
        </div>
        <div id="cart-order">
          <div className="order-detail">
            <div className="send-type">
              <p>نحوه ارسال</p>
              <select>
                <option>پست پیشتاز</option>
                <option>پیشتاز</option>
              </select>
            </div>
            <div className="send-time">
              <p>زمان ارسال</p>
              <i className="fa-regular fa-calendar-days fa-xl"></i>
            </div>
            <span>آدرس تحویل سفارش</span>
            <div className="send-loc">
              <i className="fa-solid fa-location-dot"></i>
              <p>ناکجاآباد - خیابان پرت - کوچه علی چپ</p>
              <i className="fa-solid fa-pen-to-square"></i>
            </div>
          </div>
          <div className="order-price">
            <div className="total-pr">
              <p>قیمت کالاها (6)</p>
              <h4>600,000 تومان</h4>
            </div>
            <div className="off-pr">
              <input type="text" placeholder="کد تخفیف" />
              <button>اعمال</button>
            </div>
            <div className="red-pr">
              <p>مقدار تخفیف</p>
              <h4>20,000 تومان</h4>
            </div>
            <div className="final-pr">
              <p>جمع مبلغ پرداختی</p>
              <h4>580,000 تومان</h4>
            </div>
            <Link to={"/complete-purchase"} >ثبت سفارش</Link>
          </div>
        </div>
      </div>
      <div id="pro-carousel">
                    <div className="head">
                        <h2>جدید ترین ست های زنانه</h2>
                        <Link to={"/all-product"}   >مشاهده همه</Link>
                    </div>
                    <div className="products-section">
                        <Swiper spaceBetween={30}
                            slidesPerView={4.4}
                            breakpoints={{
                                300: {
                                    slidesPerView: 1,
                                },
                                576: {
                                    slidesPerView: 2,
                                },
                                868: {
                                    slidesPerView: 3,
                                },
                                1192: {
                                    slidesPerView: 4.4,
                                },
                            }}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}>
                            <div className="swiper-wrapper">
                                <SwiperSlide>
                                    <Link to={"/single-product"} className="product">
                                        <img src={require("../assets/img/women123.png")} alt="" />
                                        <p>ست زنانه</p>
                                        <div className="rate-cath">
                                            <h6>اسپرت زنانه</h6>
                                            <div className="rate">
                                                <h6>4.1</h6>
                                                <i className="fa-sharp fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div className="price">
                                            <h4>۱,۲۵۰,۰۰۰</h4>
                                            <p>تومان</p>
                                        </div>
                                        <input type="button" value="افزودن به سبد خرید" />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to={"/single-product"} className="product">
                                        <img src={require("../assets/img/women-img (11).png")} alt="" />
                                        <p>ست زنانه</p>
                                        <div className="rate-cath">
                                            <h6>اسپرت زنانه</h6>
                                            <div className="rate">
                                                <h6>4.1</h6>
                                                <i className="fa-sharp fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div className="price">
                                            <h4>۱,۲۵۰,۰۰۰</h4>
                                            <p>تومان</p>
                                        </div>
                                        <input type="button" value="افزودن به سبد خرید" />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to={"/single-product"} className="product">
                                        <img src={require("../assets/img/8ff81791eba96c1bb5d90e483f96f7ba.jpg")} alt="" />
                                        <p>ست زنانه</p>
                                        <div className="rate-cath">
                                            <h6>اسپرت زنانه</h6>
                                            <div className="rate">
                                                <h6>4.1</h6>
                                                <i className="fa-sharp fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div className="price">
                                            <h4>۱,۲۵۰,۰۰۰</h4>
                                            <p>تومان</p>
                                        </div>
                                        <input type="button" value="افزودن به سبد خرید" />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to={"/single-product"} className="product">
                                        <img src={require("../assets/img/a5e698cd697088e7f637645a748df3d1.jpg")} alt="" />
                                        <p>ست زنانه</p>
                                        <div className="rate-cath">
                                            <h6>اسپرت زنانه</h6>
                                            <div className="rate">
                                                <h6>4.1</h6>
                                                <i className="fa-sharp fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div className="price">
                                            <h4>۱,۲۵۰,۰۰۰</h4>
                                            <p>تومان</p>
                                        </div>
                                        <input type="button" value="افزودن به سبد خرید" />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to={"/single-product"} className="product">
                                        <img src={require("../assets/img/blus-girl.png")} alt="" />
                                        <p>ست زنانه</p>
                                        <div className="rate-cath">
                                            <h6>اسپرت زنانه</h6>
                                            <div className="rate">
                                                <h6>4.1</h6>
                                                <i className="fa-sharp fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div className="price">
                                            <h4>۱,۲۵۰,۰۰۰</h4>
                                            <p>تومان</p>
                                        </div>
                                        <input type="button" value="افزودن به سبد خرید" />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to={"/single-product"} className="product">
                                        <img src={require("../assets/img/girl.png")} alt="" />
                                        <p>ست زنانه</p>
                                        <div className="rate-cath">
                                            <h6>اسپرت زنانه</h6>
                                            <div className="rate">
                                                <h6>4.1</h6>
                                                <i className="fa-sharp fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div className="price">
                                            <h4>۱,۲۵۰,۰۰۰</h4>
                                            <p>تومان</p>
                                        </div>
                                        <input type="button" value="افزودن به سبد خرید" />
                                    </Link>
                                </SwiperSlide>
                            </div>
                        </Swiper>
                    </div>
                </div>
    </>
  );
}
