import { Swiper, SwiperSlide } from "swiper/react"
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, navigation } from "swiper/modules";
import { Link } from 'react-router-dom';

export function loader(data) {
    return 0
}
export default function Cathegory() {

    return (

        <>

            <div id="banner">
                <Swiper spaceBetween={30}
                    slidesPerView={1}

                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}>

                    <div className="swiper-wrapper">

                        <SwiperSlide>
                            <img src={require("../assets/img/65f1c6e54b2c71c389ea6261bb0a0aa9.jpg")} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={require("../assets/img/3fe108af6692564cb873016ee4b68310.jpg")} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={require("../assets/img/2d7128dadbeaa5206adc883cc88189d8.jpg")} />
                        </SwiperSlide>
                    </div>
                </Swiper>

            </div>
            <div id="bottom-banner-text">
                <h2>ست بپوش!</h2>
                <span>همین الان آپلود کن و ستش رو ببین!</span>
            </div>

            <div id="cath-title">
                <span></span>
                <h2>خرید بر اساس نوع</h2>
                <span></span>
            </div>

            <div id="cathegory-post">
                <div class="cp-mobile">
                    <Swiper navigation={true} modules={[Navigation]} spaceBetween={30}
                        slidesPerView={1}

                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}>

                        <div className="swiper-wrapper">

                            <SwiperSlide>
                                <Link to={"/all-product"} class="cath-p1">
                                    <img src={require("../assets/img/9211ac44c3924e53e3c819d9e80c80e4.jfif")} />
                                    <h3>شلوار</h3>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link to={"/all-product"} class="cath-p1">
                                    <img src={require("../assets/img/6a8d26cf31eca154645131db84b023a0.jpg")} />
                                    <h3>پیراهن</h3>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link to={"/all-product"} class="cath-p1">
                                    <img src={require("../assets/img/89cec7e090dca2e6e99452109fc8223e.jpg")} />
                                    <h3>کفش</h3>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link to={"/all-product"} class="cath-p1">
                                    <img src={require("../assets/img/b96a2f2677f1890750ac4a7dac184186.jpg")} />
                                    <h3>مانتو</h3>
                                </Link>
                            </SwiperSlide>
                        </div>

                    </Swiper>

                </div>
                <Link to={"/all-product"}>
                    <div class="cath-p1">
                        <img src={require("../assets/img/9211ac44c3924e53e3c819d9e80c80e4.jfif")} />
                        <h3>شلوار</h3>
                    </div>
                </Link>
                <Link to={"/all-product"}>
                    <div class="cath-p1">
                        <img src={require("../assets/img/6a8d26cf31eca154645131db84b023a0.jpg")} />
                        <h3>پیراهن</h3>
                    </div>
                </Link>
                <Link to={"/all-product"}>
                    <div class="cath-p1">
                        <img src={require("../assets/img/89cec7e090dca2e6e99452109fc8223e.jpg")} />
                        <h3>کفش</h3>
                    </div>
                </Link>
                <Link to={"/all-product"}>
                    <div class="cath-p1">
                        <img src={require("../assets/img/b96a2f2677f1890750ac4a7dac184186.jpg")} />
                        <h3>مانتو</h3>
                    </div>
                </Link>
            </div>


            <div id="cath-title">
                <span></span>
                <h2>خرید بر اساس سایز</h2>
                <span></span>
            </div>

            <div id="size-cathegory">
                <Link to={"/all-product"} className="size-cath-path">پلاس سایز</Link>
                <Link to={"/all-product"} className="size-cath-path">سایزهای کوچیک</Link>
                <Link to={"/all-product"} className="size-cath-path">فری سایز</Link>
                <Link to={"/all-product"} className="size-cath-path">ساعت شنی</Link>
                <Link to={"/all-product"} className="size-cath-path">بارداری</Link>
            </div>

            <div id="cath-title">
                <span></span>
                <h2>خرید بر اساس قیمت</h2>
                <span></span>
            </div>

            <div id="offs">
                <div class="off-1">
                    <Link to={"/all-product"}>
                        <div class="off-txt">
                            <span>50 % درصد تحفیف ویژه پاییزه!</span>
                            <button>مشاهده</button>
                        </div>
                        <img src={require("../assets/img/b96a2f2677f1890750ac4a7dac184186.jpg")} />
                    </Link>
                    <Link to={"/all-product"}>
                        <div class="off-txt">
                            <span>فروش ویژه شلوار
                                مام استایل!</span>
                            <button>مشاهده</button>
                        </div>
                        <img src={require("../assets/img/offpic2.jpg")} />
                    </Link>
                </div>
                <div class="off-1">
                    <Link to={"/all-product"}>
                        <div class="off-txt">
                            <span>هودی های زیر
                                500 تومن!</span>
                            <button>مشاهده</button>
                        </div>
                        <img src={require("../assets/img/offpic3.jpg")} />
                    </Link>
                    <Link to={"/all-product"}>
                        <div class="off-txt">
                            <span>تا 40 درصد تخفیف
                                پیراهن های پشمی!</span>
                            <button>مشاهده</button>
                        </div>
                        <img src={require("../assets/img/offpic4.jpg")} />
                    </Link>
                </div>
            </div>

            <div id="cath-title">
                <span></span>
                <h2>خرید بر اساس برند</h2>
                <span></span>
            </div>

            <div id="brands">
                <div class="right">
                    <Link to={"/all-product"}><img src={require("../assets/img/brands1.jpg")} /></Link>
                </div>
                <div class="left">
                    <div class="left-row1">
                    <Link to={"/all-product"}><img src={require("../assets/img/brand1.jpg")} /></Link>
                    <Link to={"/all-product"}><img src={require("../assets/img/brand2.jpg")} /></Link>
                    <Link to={"/all-product"}><img src={require("../assets/img/brand3.jpg")} /></Link>
                    </div>
                    <div class="left-row1">
                    <Link to={"/all-product"}><img src={require("../assets/img/brand4.jpg")} /></Link>
                    <Link to={"/all-product"}><img src={require("../assets/img/brand5.jpg")} /></Link>
                    <Link to={"/all-product"}><img src={require("../assets/img/brand6.jpg")} /></Link>
                        
                    </div>
                </div>
            </div>

            <div id="brand2">
                <Swiper spaceBetween={30}
                    slidesPerView={7}

                    breakpoints={{
                        300: {
                            slidesPerView: 2,
                        },
                        576: {
                            slidesPerView: 3,
                        },
                        868: {
                            slidesPerView: 4,
                        },
                        1192: {
                            slidesPerView: 5,
                        },
                    }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}>

                    <div className="swiper-wrapper">

                        <SwiperSlide>
                        <Link to={"/all-product"}><img src={require("../assets/img/logobrand1.jpg")} /></Link>
                            

                        </SwiperSlide>
                        <SwiperSlide>
                        <Link to={"/all-product"}><img src={require("../assets/img/logobrand2.jpg")} /></Link>
                            


                        </SwiperSlide>
                        <SwiperSlide>
                        <Link to={"/all-product"}><img src={require("../assets/img/logobrand3.jpg")} /></Link>
                            


                        </SwiperSlide>
                        <SwiperSlide>
                        <Link to={"/all-product"}><img src={require("../assets/img/logobrand4.jpg")} /></Link>
                            

                        </SwiperSlide>
                        <SwiperSlide>
                        <Link to={"/all-product"}><img src={require("../assets/img/logobrand5.jpg")} /></Link>
                            

                        </SwiperSlide>
                        <SwiperSlide>
                        <Link to={"/all-product"}><img src={require("../assets/img/logobrand6.jpg")} /></Link>
                            

                        </SwiperSlide>
                        <SwiperSlide>
                        <Link to={"/all-product"}><img src={require("../assets/img/logobrand5.jpg")} /></Link>
                            

                        </SwiperSlide>
                        <SwiperSlide>
                        <Link to={"/all-product"}><img src={require("../assets/img/logobrand1.jpg")} /></Link>
                            

                        </SwiperSlide>
                        <SwiperSlide>

                            
                            <Link to={"/all-product"}><img src={require("../assets/img/logobrand2.jpg")} /></Link>

                        </SwiperSlide>
                        <SwiperSlide>
                        <Link to={"/all-product"}><img src={require("../assets/img/logobrand7.jpg")} /></Link>
                            

                        </SwiperSlide>
                    </div>

                </Swiper>

            </div>

        </>

    )

}